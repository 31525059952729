<template>
  <v-container>
    <v-row>
      <v-col :cols="12">
        <span class="text-h5">Detalhe do plano de assinatura</span>
      </v-col>
    </v-row>

    <ButtonBar>
      <v-btn type="button" color="primary" outlined @click="goBack"
        >Voltar</v-btn
      >
      <v-btn
        type="button"
        color="error"
        @click="remove"
        v-if="!isNew && !context.provider.isReadonly"
        v-auth="'SUBSCRIPTION_PLAN.REMOVE'"
        :loading="removeButton.loading"
        >Excluir</v-btn
      >
      <v-btn
        type="button"
        color="primary"
        @click="update"
        v-if="!isNew && hasPermission('SUBSCRIPTION_PLAN.VALUE')"
        v-auth="'SUBSCRIPTION_PLAN.VALUE'"
        :loading="updateButton.loading"
        >Alterar</v-btn
      >
      <v-btn
        type="button"
        color="primary"
        @click="add"
        v-if="isNew && !context.provider.isReadonly"
        v-auth="'SUBSCRIPTION_PLAN.ADD'"
        :loading="addButton.loading"
        >Adicionar</v-btn
      >
    </ButtonBar>

    <v-form ref="form" v-model="validForm">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="card-title">Dados básicos</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Nome"
                    maxlength="80"
                    v-model="subscriptionPlan.name"
                    :disabled="context.provider.isReadonly"
                    required
                    :rules="[$rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Código externo"
                    maxlength="50"
                    v-model="subscriptionPlan.external_id"
                    :disabled="context.provider.isReadonly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    :items="data.subscriptionPlanStatus"
                    label="Status"
                    v-model="subscriptionPlan.status"
                    :disabled="context.provider.isReadonly"
                    required
                    :rules="[$rules.required]"
                    item-value="id"
                    item-text="name"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Descrição"
                    maxlength="100"
                    v-model="subscriptionPlan.description"
                    :disabled="context.provider.isReadonly"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Valor PF"
                    maxlength="100"
                    v-model="subscriptionPlan.valor_pf"
                    prefix="R$"
                    type="number"
                    min="0"
                    step=".01"
                    :rules="[$rules.number]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Valor PJ"
                    maxlength="100"
                    v-model="subscriptionPlan.valor_pj"
                    prefix="R$"
                    type="number"
                    min="0"
                    step=".01"
                    :rules="[$rules.number]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="Valor total"
                    maxlength="100"
                    v-model="subscriptionPlan.valor_total"
                    prefix="R$"
                    type="number"
                    readonly
                    :rules="[$rules.number]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="ID"
                    maxlength="400"
                    v-model="subscriptionPlan.id"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      label="URNS (Acesso DGO separador , )"
                      maxlength="490"
                      v-model="subscriptionPlan.urns"
                    ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import subscriptionPlanApi from "@/common/api/subscription-plan.api";
import clientApi from "@/common/api/client.api";
import subscriptionPlanStatus from "@/common/data/subscription-plan-status.data";
import ButtonBar from "@/components/layout/ButtonBar";
import toaster from "@/common/util/toaster";
import authApi from "@/common/api/auth.api";

export default {
  name: "SubscriptionPlanDetails",

  components: { ButtonBar },

  data() {
    return {
      data: {
        subscriptionPlanStatus: subscriptionPlanStatus,
      },

      validForm: true,
      isNew: true,
      context: { provider: {} },
      subscriptionPlan: {
        status: "A",
      },
      removeButton: {
        loading: false,
      },
      updateButton: {
        loading: false,
      },
      addButton: {
        loading: false,
      },
    };
  },
  created() {
    this.load(this.$route.params.id);
  },

  methods: {

    hasPermission(permission) {
      return authApi.hasPermission(permission);
    },

    load(subscriptionPlanId) {
      if (subscriptionPlanId !== "novo") {
        this.isNew = false;

        subscriptionPlanApi
          .get(clientApi.getSelectedClient().id, subscriptionPlanId)
          .then((result) => {
            this.context = result.data._context;
            this.subscriptionPlan = result.data;
            this.subscriptionPlan.fullname = this.subscriptionPlan.name;
          });
      }
    },

    goBack() {
      this.$router.push(`/planos-assinatura`);
    },

    async add() {
      this.$refs.form.validate();

      if (await this.$root.$confirm(this.validForm, "subscriptionPlan.add")) {
        let data = {
          external_id: this.subscriptionPlan.external_id,
          name: this.subscriptionPlan.name,
          description: this.subscriptionPlan.description,
          logo_url: this.subscriptionPlan.logo_url,
          status: this.subscriptionPlan.status,
          imported: false,
          urns: this.subscriptionPlan.urns
        };

        this.addButton.loading = true;

        subscriptionPlanApi
          .add(clientApi.getSelectedClient().id, data)
          .then(() => {
            this.addButton.loading = false;

            toaster.show("Plano de assinatura adicionado com sucesso!");
            this.goBack();
          })
          .catch(() => (this.addButton.loading = false));
      }
    },

    async update() {
      this.$refs.form.validate();

      if (
        await this.$root.$confirm(this.validForm, "subscriptionPlan.update")
      ) {
        let data = {
          external_id: this.subscriptionPlan.external_id,
          name: this.subscriptionPlan.name,
          description: this.subscriptionPlan.description,
          logo_url: this.subscriptionPlan.logo_url,
          status: this.subscriptionPlan.status,
          valor_pf: this.subscriptionPlan.valor_pf,
          valor_pj: this.subscriptionPlan.valor_pj,
          urns: this.subscriptionPlan.urns
        };

        this.updateButton.loading = true;

        subscriptionPlanApi
          .update(
            clientApi.getSelectedClient().id,
            this.subscriptionPlan.id,
            data
          )
          .then(() => {
            this.updateButton.loading = false;

            toaster.show("Plano de assinatura alterado com sucesso!");
            this.goBack();
          })
          .catch(() => (this.updateButton.loading = false));
      }
    },

    async remove() {
      if (await this.$root.$confirm(true, "subscriptionPlan.remove")) {
        this.removeButton.loading = true;

        subscriptionPlanApi
          .remove(clientApi.getSelectedClient().id, this.subscriptionPlan.id)
          .then(() => {
            this.removeButton.loading = false;

            toaster.show("Plano de assinatura excluído com sucesso!");
            this.goBack();
          })
          .catch(() => (this.removeButton.loading = false));
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
